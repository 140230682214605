@import '../../theme.css';

button.button-component {
    border: 0;
    border-radius: 4px;
    padding: 6px 16px;
    min-width: 64px;
    align-items: center;
    box-sizing: border-box;
	background-color: transparent;
    text-transform: none;
	cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

button.button-component:active {
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

button.button-component:focus {
    outline: none;
    background-color: var(--theme-general-button-focus-color);
}

button.button-component.button-icon {
	color: inherit;
	flex: 0 0 auto;
    padding: 12px;
    min-width: unset;
    overflow: visible;
    text-align: center;
    border-radius: 50%;
	cursor: pointer;
	border: 0;
    background-color: transparent;
}

button.button-component.button-icon:focus {
    background-color: var(--theme-general-button-focus-color);
}

button.button-component.button-icon:hover {
    background-color: var(--theme-general-icon-image-button-hover-color);
}

button.button-component.button-sidebar-icon {
    padding: 6px 8px;
    min-width: unset;
	display: inherit;
    align-items: center;
    justify-content: center;
}

button.button-component.contained-primary {
	color: var(--text-color);
	background-color: var(--primary-color);
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

button.button-component.contained-primary:hover, button.button-component.contained-primary:focus {
	background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)) ;
}

button.button-component.contained-gray:hover, button.button-component.contained-gray:focus {
	background-color: #d5d5d5;
}

button.button-component.button-background-hover:hover {
	background-color: var(--theme-general-icon-image-button-hover-color);
}

button.button-component span.button-label-text {
	width: 100%;
	display: inherit;
	align-items: inherit;
	justify-content: inherit;
}

button.button-component span.button-label-icon {
    width: 100%;
    display: flex;
    align-items: inherit;
    justify-content: inherit;
}

button.button-component span.button-label-sidebar-icon{
    display: flex;
    width: 1.7rem;
    height: 1.7rem;
    align-items: center;
    justify-content: center;
}

button.button-component span.button-label-sidebar-icon img {
    margin: 0 !important;
}

button.button-component.contained-secondary {
	border-style: solid;
	border-width: 1px;
	color: var(--theme-buy-window-text-color);
	border-color: rgb(133, 133, 133);
}

button.button-component.hide {
    display: none;
}

.typography-small {
	font-family: 'Assistant', sans-serif;
	font-size: 1rem;
    font-weight: 500;
    line-height: 1.75;
}