@import '../../theme.css';

.chat-window-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;
	flex: 1;
}

.chat-header-bar {
	position: absolute;
	width: 100%;
    display: flex;
    z-index: 1100;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-direction: column;
	box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
	color: #fff;
	background-color: var(--theme-chat-window-header-background-color);
}

.chat-header-bar .chat-header-space {
	flex-grow: 1;
}

.chat-header-bar .chat-header-toolbar {
	display: flex;
    position: relative;
    align-items: center;
	padding-left: 16px;
    padding-right: 16px;
	min-height: unset;
}

.chat-header-bar .button-icon:hover svg,
.chat-header-bar .button-icon:focus svg {
	fill: var(--theme-chat-window-header-button-color-hover) !important;
}

.chat-window-messages {
    flex: 1;
    min-height: 0;
    font-weight: 300;
	height: 100%;
}

.ac-horizontal-separator {
	height: 0px !important;
}

.webchat__basic-transcript__scrollable {
	position: relative;
}

	/* total width */
	.webchat__basic-transcript__scrollable::-webkit-scrollbar {
		background-color: var(--theme-webkit-scrollbar-background);
		opacity: 0;
		width: 12px
	}

	/* background of the scrollbar except button or resizer */
	.webchat__basic-transcript__scrollable::-webkit-scrollbar-track {
		background-color: var(--theme-webkit-scrollbar-background);
	}

	.scrollbar::-webkit-scrollbar-track:hover {
		background-color: var(--theme-webkit-scrollbar-hover);
	}


	.webchat__basic-transcript__scrollable::-webkit-scrollbar-button {
		display: none
	}
	.webchat__basic-transcript__scrollable::-webkit-scrollbar-thumb {
		background-color: rgba(0,0,0,0);
		border-radius: 12px;
		border: 4px solid var(--theme-webkit-scrollbar-background);
	}

	.webchat__basic-transcript__scrollable::-webkit-scrollbar-thumb:hover {
		background-color: var(--theme-webkit-scrollbar-hover);
		border: 3px solid var(--theme-webkit-scrollbar-hover);
	}

	.webchat__basic-transcript__scrollable:hover::-webkit-scrollbar-thumb {
		background-color: var(--theme-webkit-scrollbar-background-hover);
	}

.chat-window-messages .ac-pushButton {
	font-family: 'Assistant', Arial, Helvetica, sans-serif;
	font-weight: 600;
	font-size: 16px;
	outline: none;
	border-radius: 8px !important;
	padding-top: 6px !important;
	padding-bottom: 6px !important;
	min-height: 32px !important;
	cursor: pointer;
	background-color: var(--theme-bubble-button-background-color) !important;
	border-color: rgb(133, 133, 133) !important;
	margin: 0px;
}

.chat-header-bar .popover-button-container {
	position: relative;
}

.chat-header-bar .popover {
	outline: 0;
    position: absolute;
	left: 50%;
    transform: translateX(-50%);
    min-width: 16px;
    min-height: 16px;
	box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
	border-radius: 4px;
	background-color: var(--theme-dialog-background-color);
	z-index: 3;
}

.chat-header-bar .popover.popover-hidden {
	visibility: hidden;
}

.chat-header-bar .popover button {
	padding-top: 6px;
	padding-bottom: 6px;
	width: 100%;
	min-width: unset;
	min-height: 48px;
	font-size: 1.14rem;
	font-weight: 400;
	line-height: 1.5;
    white-space: nowrap;
	color: var(--theme-dialog-text-color);
	text-align: left;
}

.chat-header-bar .popover button:hover {
	background-color: var(--theme-general-icon-image-button-hover-color);
}

/* need to define by theme
	-- need color for light theme to be override by bot default theme color
	-- need color for dark theme to override the bot default theme color by 'important'
*/
.theme-dark .chat-window-messages .ac-pushButton {
	color: var(--theme-bubble-button-text-color) !important;
}

.chat-window-messages .ac-pushButton > img {
	height: 18px !important;
	width: 18px !important;
	filter: var(--theme-bubble-button-menu-icon-filter); /* convert icon to white in dark theme*/
	-webkit-filter: var(--theme-bubble-button-menu-icon-filter);
}

.chat-window-messages .ac-textBlock {
	text-align: center !important;
	white-space: initial !important;
	margin-bottom: 0.4rem !important;
}

/* adaptive card primary header -- i.e. show promotions */
.chat-window-messages .ac-container .ac-textBlock p {
	color: var(--theme-adaptive-card-primary-header-color) !important;
}

/* adaptive card secondary header -- i.e. show promotions */
.chat-window-messages .ac-container .ac-textBlock ~ .ac-textBlock p {
	/*color: var(--theme-adaptive-card-secondary-header-color) !important;*/
	font-weight: bold;
	font-style: italic;
}

/* appointment confirmation -- i.e. arrange for test drive */
.chat-window-messages .ac-container .ac-textBlock ~ .ac-textBlock p .ac-anchor {
	color: var(--theme-chat-window-link-color);
}

.chat-window-messages .carousel-film-strip li {
    max-width: none !important;
}

	.chat-window-messages .content ul li {
		min-width: calc(85vw - 30px - 13px - 17px - 17px);
	}

@media only screen and (max-device-width: 991px) {
	.chat-window-messages .content ul li {
		min-width: calc(85vw - 30px - 13px - 2px - 2px);
	}
}

/* make adaptive cards in filmstrips the same height and center image */
.chat-window-messages li.webchat__carousel-filmstrip-attachment > .webchat__bubble {
    height: 100%;
}

	.chat-window-messages li.webchat__carousel-filmstrip-attachment > .webchat__bubble > .webchat__bubble__content > .webchat__adaptive-card-renderer {
		height: 100%;
	}

	.chat-window-messages li.webchat__carousel-filmstrip-attachment > .webchat__bubble > .webchat__bubble__content > .webchat__adaptive-card-renderer > .ac-adaptiveCard {
		height: 100%;
	}

	.chat-window-messages li.webchat__carousel-filmstrip-attachment > .webchat__bubble > .webchat__bubble__content > .webchat__adaptive-card-renderer > .ac-adaptiveCard > .ac-container:first-child {
		flex-grow: 1 !important;
	}

	.chat-window-messages li.webchat__carousel-filmstrip-attachment > .webchat__bubble > .webchat__bubble__content > .webchat__adaptive-card-renderer > .ac-adaptiveCard > .ac-container:first-child > div:first-child {
		max-height: 250px;
		flex-grow: 1 !important;
		padding-bottom: 8px;
	}

	.chat-window-messages li.webchat__carousel-filmstrip-attachment > .webchat__bubble > .webchat__bubble__content > .webchat__adaptive-card-renderer > .ac-adaptiveCard > .ac-container:first-child > div:first-child > .ac-image {
		width: auto !important;
		max-width: 100% !important;
		max-height: 100% !important;
		margin: auto auto !important;
	}

.chat-window-messages .ac-adaptiveCard .ac-image {
	margin-top: 0px !important;
	margin-bottom: 8px !important;
}

.chat-window-messages .chat-window-list > li:not(.hide-timestamp)
{
    margin-bottom:10px;
}

.chat-window-messages li {
	text-align: left;
}

.chat-window-messages .chat-row {
	margin-left: 9px;
	margin-right: 9px;
}

.chat-window-messages .markdown ul {
	list-style-type: square;
	margin: 20px 0px 20px 0px;	
    padding-left: 20px;
}

.chat-window-messages .markdown p {
	margin: 0.3rem 0;
}

.chat-window-messages .markdown h4 {
	margin-bottom: 0.5em;
	font-size: 16px;
}

.chat-window-messages p {
	margin: 0;
	line-height:1.2rem;
}

.chat-window-messages p a {
	color: var(--theme-chat-window-link-color);
}

.chat-window-messages .slider {
	z-index: 10;
}

.chat-window-messages .webchat__basic-transcript__activity-body .webchat__stacked-layout {
	margin-left: 13px;
	margin-right: 11px;
}

.chat-window-messages .webchat__stacked-layout__main .webchat__stacked-layout__content .webchat__bubble {
	max-width: 85% !important;
}
	.chat-window-messages .webchat__stacked-layout__main .webchat__stacked-layout__content .webchat__bubble.webchat__bubble--from-user {
		max-width: 80% !important;
	}

.chat-window-messages .webchat__stacked-layout__main .webchat__stacked-layout__content .webchat__bubble .webchat__bubble__content {
	min-height: 32px;
	border-radius: 10px;
}
.chat-window-messages .webchat__stacked-layout__main .webchat__stacked-layout__content .webchat__bubble .webchat__bubble__content > p, .chat-window-messages .webchat__stacked-layout__main .webchat__stacked-layout__content .webchat__bubble .webchat__bubble__content > .markdown {
	padding: 8px 12px;
}
	.chat-window-messages .webchat__stacked-layout__main .webchat__stacked-layout__content .webchat__bubble .webchat__bubble__nub-pad{
		width: 8px;
	}

.chat-window-messages .from-user .webchat__stacked-layout__main .webchat__stacked-layout__content .webchat__bubble .webchat__bubble__nub-pad {
	width: 0px;
}

.chat-window-messages .webchat__carousel-layout .webchat__carousel-filmstrip__main .webchat__carousel-filmstrip__content .webchat__carousel-filmstrip__nub-pad {
	width: 10px;
}
.chat-window-messages .webchat__basic-transcript__activity  {
	margin-bottom: 8px;
}

.chat-header-icon {
	padding: 8px !important;
}

.chat-header-icon.edge-start {
	margin-right: 5px;
}

.chat-window-close-button {
	padding: 8px !important;
	position: absolute !important;
	top: 0px;
	right: 13px;
	z-index: 2;
}

.chat-header-bar .chat-language {
	min-width: 25px;
	padding: 0;
	margin: 0;
}

.webchat__suggested-actions .webchat__suggested-action {
	padding: 10px 5px;
}

	.webchat__suggested-actions .webchat__suggested-action > button.webchat__suggested-action__button {
		height: 32px;
	}


button.icon-button svg {
    fill: #000000 !important;
}
.send-textbox, .send-textarea-input {
    background-color: #ececed !important;
    color: #000 !important;
}

button.icon-button:hover {
    background-color: #e0e0e0 !important;
}

button.icon-button {
    background-color: #ececed !important;
}

.scroll-to-end {
    border-radius: 10px;
}

.single-message-in-block.from-bot .webchat__bubble__content {
	border-top-left-radius: 5px !important;
	border-top-right-radius: 20px !important;
	border-bottom-left-radius: 20px !important;
	border-bottom-right-radius: 20px !important;
}

.first-message-in-block {
	padding-top:5px;
}

.first-message-in-block.from-bot .webchat__bubble__content {
	border-top-left-radius: 5px !important;
	border-top-right-radius: 20px !important;
	border-bottom-left-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
}

.last-message-in-block.from-bot .webchat__bubble__content {
	border-top-left-radius: 5px !important;
	border-top-right-radius: 5px !important;
	border-bottom-left-radius: 20px !important;
	border-bottom-right-radius: 20px !important;
}

.single-message-in-block.from-user .webchat__bubble__content {
	border-top-left-radius: 20px !important;
	border-top-right-radius: 5px !important;
	border-bottom-left-radius: 20px !important;
	border-bottom-right-radius: 20px !important;
}

.first-message-in-block.from-user .webchat__bubble__content {
	border-top-left-radius: 20px !important;
	border-top-right-radius: 5px !important;
	border-bottom-left-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
}

.last-message-in-block.from-user .webchat__bubble__content {
	border-top-left-radius: 5px !important;
	border-top-right-radius: 5px !important;
	border-bottom-left-radius: 20px !important;
	border-bottom-right-radius: 20px !important;
}

