.theme-light {
	/************ Chat window ************/
	/* help menu buttons */
	--theme-bubble-button-background-color: #ffffff;
	--theme-bubble-button-text-color: unset; /* not applied, since defined by .theme-themeName class, even applied it will be override by default bot theme color */
	--theme-bubble-button-menu-icon-filter: unset;
	/* adaptive card -- i.e. show promotions, browse new vehicles */
	--theme-adaptive-card-primary-header-color: #000000;
	--theme-adaptive-card-secondary-header-color: rgba(0, 0, 0, 0.4);
	/* general link -- i.e. appointment confirmation email filed <-- arrange for test drive */
	--theme-chat-window-link-color: -webkit-link;
	/* welcome message */
	--theme-webchat-welcome-background-color: #ffffff;
	--theme-webchat-welcome-send-border-color: #e6e6e6;
	--theme-webchat-welcome-message-background-color: #ececed;
	--theme-webchat-welcome-message-border-color: #ffffff;
	--theme-webchat-welcome-message-color: #000000;
	/************ Chat Window Header ************/
	--theme-chat-window-header-background-color: rgba(255, 255, 255, 0.8);
	/************ Chat Window Header Buttons ************/
	--theme-chat-window-header-button-color-hover: #666;
	/************ Chat Window Side Bar ************/
	--theme-chat-window-side-bar-divider-background-color: rgba(0, 0, 0, 0.12);
	/************ Auth Small window ************/
	--theme-auth-small-window-background-color: #ffffff;
	--theme-auth-small-window-primary-message-color: #666666;
	--theme-auth-small-window-secondary-message-color: #000000;
	--theme-auth-small-window-bubble-box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 4px;
	--theme-auth-small-window-bubble-background-color: #f0f0f0;
	--theme-auth-small-window-social-login-text-color: #000000;
	--theme-auth-small-window-social-login-button-border-color: #cccccc;
	--theme-auth-small-window-social-login-button-background-color: #ffffff;
	--theme-auth-small-window-social-login-apple-button-filter: unset;
	--theme-auth-small-window-social-login-button-box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.25);
	--theme-auth-small-window-social-login-get-started-button-background-color: #e0e0e0;
	--theme-auth-small-window-sms-input-border: 2px solid rgb(118, 118, 118);
	--theme-auth-small-window-sms-input-background-color: #ffffff;
	--theme-auth-small-window-sms-input-text-color: #000000;
	/************ Auth Legacy window ************/
	--theme-auth-legacy-window-background-color: #f0f0f0;
	--theme-auth-legacy-window-bubble-background-color: #ffffff;
	--theme-auth-legacy-window-secondary-message-color: #000000;
	/************ SMS Dialog ************/
	--theme-sms-dialog-text-color-disabled: rgba(0, 0, 0, 0.38);
	--theme-sms-dialog-text-color: rgb(0, 0, 0);
	--theme-sms-dialog-text-light-color: rgba(0, 0, 0, 0.38);
	--theme-sms-dialog-border-color: rgba(0, 0, 0, 0.2);
	/************ Locale Selection Dialog ************/
	--theme-local-selection-button-text-color: unset; /* not applied, since defined by .theme-themeName class, light theme should use bot theme color */
	--theme-local-selection-button-border-color: unset; /* not applied, since defined by .theme-themeName class, light theme should use bot theme color */
	/************ Dialog ************/
	--theme-dialog-background-color: #fff;
	--theme-dialog-text-color: rgba(0, 0, 0, 0.87);
	/************ General ************/
	--theme-general-icon-image-button-hover-color: rgba(0, 0, 0, 0.04);
	--theme-general-button-focus-color: rgba(0, 0, 0, 0.26);
	/************ Buy Window ************/
	--theme-buy-window-background-color: #ffffff;
	--theme-buy-window-text-color: #000000;
	/************ Webkit Scrollbar *******/
	--theme-webkit-scrollbar-background: #ffffff;
	--theme-webkit-scrollbar-hover: #f4f4f4;
	--theme-webkit-scrollbar-background-hover: #a0a0a5;
}

.theme-dark {
	/************ Chat window ************/
	/* help menu buttons */
	--theme-bubble-button-background-color: #2e2e2e;
	--theme-bubble-button-text-color: #ffffff;
	--theme-bubble-button-menu-icon-filter: invert(100%);
	/* adaptive card -- i.e. show promotions, browse new vehicles */
	--theme-adaptive-card-primary-header-color: #ffffff;
	--theme-adaptive-card-secondary-header-color: rgba(255, 255, 255, 0.6);
	/* general link -- i.e. appointment confirmation email filed <-- arrange for test drive */
	--theme-chat-window-link-color: #4db2ff;
	/* welcome message */
	--theme-webchat-welcome-background-color: #212121;
	--theme-webchat-welcome-send-border-color: #454545;
	--theme-webchat-welcome-message-background-color: #2e2e2e;
	--theme-webchat-welcome-message-border-color: #454545;
	--theme-webchat-welcome-message-color: #ffffff;
	/************ Chat Window Header ************/
	--theme-chat-window-header-background-color: rgba(33, 33, 33, 0.8);
	/************ Chat Window Header Buttons ************/
	--theme-chat-window-header-button-color-hover: #ffffff;
	/************ Chat Window Side Bar ************/
	--theme-chat-window-side-bar-divider-background-color: rgba(255, 255, 255, 0.12);
	/************ Auth Small window ************/
	--theme-auth-small-window-background-color: #212121;
	--theme-auth-small-window-primary-message-color: #e0e0e0;
	--theme-auth-small-window-secondary-message-color: #ffffff;
	--theme-auth-small-window-bubble-box-shadow: rgba(255, 255, 255, 0.3) 1px 1px 4px;
	--theme-auth-small-window-bubble-background-color: #2e2e2e;
	--theme-auth-small-window-social-login-text-color: #ffffff;
	--theme-auth-small-window-social-login-button-border-color: rgb(118, 118, 118);
	--theme-auth-small-window-social-login-button-background-color: #212121;
	--theme-auth-small-window-social-login-apple-button-filter: invert(100%);
	--theme-auth-small-window-social-login-button-box-shadow: 0 2px 3px 1px rgb(255, 255, 255, 0.25);
	--theme-auth-small-window-social-login-get-started-button-background-color: rgba(255, 255, 255, 0.3);
	--theme-auth-small-window-sms-input-border: 2px solid #454545;
	--theme-auth-small-window-sms-input-background-color: #212121;
	--theme-auth-small-window-sms-input-text-color: #ffffff;
	/************ Auth Legacy window ************/
	--theme-auth-legacy-window-background-color: #212121;
	--theme-auth-legacy-window-bubble-background-color: #2e2e2e;
	--theme-auth-legacy-window-secondary-message-color: #ffffff;
	/************ SMS Dialog ************/
	--theme-sms-dialog-text-color-disabled: rgba(255, 255, 255, 0.5);
	--theme-sms-dialog-text-color: rgb(255, 255, 255);
	--theme-sms-dialog-text-light-color: rgba(255, 255, 255, 0.5);
	--theme-sms-dialog-border-color: rgba(255, 255, 255, 0.3);
	/************ Locale Selection Dialog ************/
	--theme-local-selection-button-text-color: rgba(255, 255, 255, 0.9);
	--theme-local-selection-button-border-color: rgba(255, 255, 255, 0.5);
	/************ Dialog ************/
	--theme-dialog-background-color: #424242;
	--theme-dialog-text-color: #fff;
	/************ General ************/
	--theme-general-icon-image-button-hover-color: rgba(255, 255, 255, 0.08);
	--theme-general-button-focus-color: rgba(255, 255, 255, 0.3);
	/************ Buy Window ************/
	--theme-buy-window-background-color: #343434;
	--theme-buy-window-text-color: #ffffff;
	/************ Webkit Scrollbar *******/
	--theme-webkit-scrollbar-background: #212121;
	--theme-webkit-scrollbar-hover: #212121;
	--theme-webkit-scrollbar-background-hover: #a0a0a5;
}
