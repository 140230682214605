@import '../../theme.css';

.webchat-welcome {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--theme-webchat-welcome-background-color);
}

.webchat-welcome-connecting-container {
    flex: 1;
    position: relative;
}

.webchat-welcome-connecting {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 5px;
    margin-bottom: 4px;
    margin-left: 15px;
}

.webchat-welcome-connecting-text {
    font-family: 'Assistant', Arial, sans-serif;
    font-size: 75%;
    color: #767676;
}

.webchat-welcome-messages {
    position: relative;
    flex: 1;
}

.webchat-welcome-send {
    display: flex;
    flex-shrink: 0;
    align-items: stretch;
    min-height: 40px;
    border-top: 1px solid var(--theme-webchat-welcome-send-border-color);
}

.webchat-welcome-send-box {
    display: flex;
    flex: 1;
    padding: 13px;
    font-family: 'Assistant', Arial, sans-serif;
    font-size: 16px;
}

.webchat-welcome-send-input {
    flex: 1;
    max-height: 400px;
    padding: 0;
    border: 0;
    outline: 0;
    font-family: inherit;
    font-size: inherit;
    color: var(--theme-webchat-welcome-message-color);
    background-color: transparent;
}

.webchat-welcome-send-button {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 40px;
    padding: 0;
    border: 0;
    outline: 0;
    background-color: transparent;
}

.webchat-welcome-initial {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    margin-bottom: 20px;
    width: 100%;
}

.webchat-welcome-initial-avatar {
    width: 25px;
    height: 25px;
    margin-left: 13px;
}

.webchat-welcome-initial-avatar > img {
    width: 100%;
    height: 100%;
    clip-path: circle();
}

.webchat-welcome-initial-message-container {
    flex: 1;
    min-height: 32px;
    margin-right: 13px;
    text-align: left;
}

.webchat-welcome-initial-message-box {
    position: relative;
    display: flex;
    max-width: 85% !important;
}

.webchat-welcome-initial-message-pad {
    flex-shrink: 0;
    width: 10px;
}

.webchat-welcome-initial-message {
    border: 1px solid;
    border-color: var(--theme-webchat-welcome-message-border-color);
    border-radius: 5px 20px 20px 20px;
    padding: 8px 12px;
    background-color: var(--theme-webchat-welcome-message-background-color);
    color: var(--theme-webchat-welcome-message-color);
}

.chat-window-messages .webchat-welcome-initial-message > p {
    margin: 0.3rem 0;
}