@import './theme.css';

html,
body,
#root {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	overflow: hidden;
	font-size: 16px;

	--primary-color: #2770b7;
	--primary-color-50: #2770b780;
	--primary-color-04: #2770b70a;
	--primary-color-4f: #276fb74f;
	--text-color: #ffffff;
}

.app {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	font-family: 'Assistant', Arial, sans-serif;
}

.window-container {
	height: 100%;
	margin: 10px;
	box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
	overflow: hidden;
	border-radius: 6px;
}

.window-container.buy-window-opened {
	border-radius: 0 6px 6px 0;
	clip-path: inset(-10px -10px -10px 0);
}

.window-container.mobile {
	margin: 0px;
	box-shadow: none;
	border-radius: 0px;
	overflow-y: auto;
}

.chat-windows-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
}

.typography {
	font-family: 'Assistant', sans-serif;
	font-size: 1.14rem;
	margin: 0;
	line-height: 1.5;
}

svg.icon-image {
	width: 25.7px;
	height: 25.7px;
}

.edge-end {
	margin-right: -12px;
}

.edge-start {
	margin-left: -12px;
}

/* Slide transition - up */
.css-transition-up-enter {
	transform: translateY(110%);
}

.css-transition-up-enter-active {
	transform: translateY(0);
	transition: transform 400ms;
}

.css-transition-up-exit {
	transform: translateY(0);
}

.css-transition-up-exit-active {
	transform: translateY(110%);
	transition: transform 400ms;
}

.css-transition-up-exit-done {
	transform: translateY(110%);
}

/* Slide transition - left */
.css-transition-left-enter {
	transform: translateX(110%);
}

.css-transition-left-enter-active {
	transform: translateX(0);
	transition: transform 400ms;
}

/* .css-transition-left-enter-done {
	transform: translateX(0);
} */

.css-transition-left-exit {
	transform: translateX(0);
}

.css-transition-left-exit-active {
	transform: translateX(110%);
	transition: transform 400ms;
}

.css-transition-left-exit-done {
	transform: translateX(110%);
}

/* Slide transition - right */
.css-transition-right-enter {
	transform: translateX(-110%);
}

.css-transition-right-enter-active {
	transform: translateX(0);
	transition: transform 400ms;
}

.css-transition-right-enter-done {
	transform: translateX(0);
}

.css-transition-right-exit {
	transform: translateX(0);
}

.css-transition-right-exit-active {
	transform: translateX(-110%);
	transition: transform 400ms;
}

.css-transition-right-exit-done {
	transform: translateX(-110%);
}
