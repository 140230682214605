.tooltip-wrapper {
    display: inline-block;
    position: relative;
}

.tooltip-wrapper .tooltip-span {
    background-color: #606060;
    color: #ffffff;
    padding: 5px 7px;
    font-size: 11px;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    opacity: 0.9;
    white-space: nowrap;
}

.tooltip-span.top {
    top: -30px;
}

.tooltip-span.right {
    left: calc(100% + 5px);
    top: 50%;
    transform: translateX(0) translateY(-50%);  
}

.tooltip-span.left {
    left: auto;
    right: calc(100% + 5px);
    top: 50%;
    transform: translateX(0) translateY(-50%);  
}

.tooltip-span.bottom {
    bottom: -30px;
}