.chat-welcome {
    height: 100%;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.chat-welcome-mobile {
    margin: 15px 10px 15px 10px;
}

/*.chat-welcome-header {
    padding: 15px 20px 10px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}*/
.chat-welcome-mobile-close-button {
    position: absolute !important;
    top: 6px;
    right: -6px;
    padding: 6px !important;
    z-index: 1;
}

.chat-welcome-mobile-close-button svg {
    filter: drop-shadow( 2px 2px 2px rgba(122, 122, 122, .7));
}

.chat-welcome-close-button {
    position: absolute !important;
    top: -12px;
    right: 10px;
    padding: 6px !important;
    z-index: 1;
}

.chat-welcome-close-button svg {
    filter: drop-shadow( 2px 2px 2px rgba(122, 122, 122, .7));
}

.chat-welcome-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px 5px;
    justify-content: center;
    position: relative;
}

.chat-welcome-avatar {
    width: 60px;
    margin-right: 5px;
    object-fit: contain;
}

.chat-welcome-bubble {
    position: relative;
    background-color: #f0f0f0;
    border-radius: 3px;
    padding: 12px 6px;
    margin-left: 10px;
    margin-right: 5px;
    box-shadow: rgba(0,0,0,0.2) 1px 1px 4px;
    width: auto;
}

.chat-welcome-bubble:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-right-color: #f0f0f0;
    border-left: 0;
    border-bottom: 0;
    margin-top: -10px;
    margin-left: -15px;
}

.chat-welcome-secondary-welcome {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    margin: 0 0 0 0px;
    padding: 0 15px;
}

.chat-welcome-mobile {
    display: block;
    padding: 13px;
    text-align: left !important;
    line-height: 15px;
    border-radius: 6px;
    background-color: #fff;
    font-size: 13px !important;
    color: #000 !important;
    box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.25);
    z-index: 1000;
    box-sizing: content-box;
}

#dealeraibot-root.left .chat-welcome-mobile {
    margin-left: 20px;
}
